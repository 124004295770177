import axios from 'axios'
import cloneDeep from 'lodash.clonedeep'
import startCase from 'lodash/startCase';
import Waitlist from './Components/waitlist.vue'
import RegistrationForm from './Components/registration-form.vue'
import Throttle from './Components/throttle.vue'
import AccessCode from './Components/access-code.vue'
import ModalRemindMe from './Components/modal-remind-me.vue'
import { ShareNetwork } from 'vue-social-sharing'

import {AxiosRedirectIntercepted} from '../Shared/errors'
import {
    trackAddToCart,
    trackBeginCheckout,
    trackIdle,
    trackShare,
    trackViewProduct
} from '../Shared/analytics'
import { injectHideyShowyHack } from "./hideyShowyHack";
import { createApp, currencyFormat } from '../Shared/common'

const RESERVATION_FAILED_STATUS = 1

const hasCartExpired = () => {
    var field = 'expired'
    var url = window.location.href
    if (url.indexOf('?' + field + '=') != -1) return true
    else if (url.indexOf('&' + field + '=') != -1) return true
    return false
}

const hasCartSeatFulfillmentFailed = () => {
    var field = 'seatFulfillmentFailed'
    var url = window.location.href
    if (url.indexOf('?' + field + '=') != -1) return true
    else if (url.indexOf('&' + field + '=') != -1) return true
    return false
}

const preLoadeddata = cloneDeep(window.preLoadeddata);

const vm = createApp({
    data: () => Object.assign(preLoadeddata, {
        pageIndex: 1,
        showModal: false,
        modalTitle: '',
        modalType: 'info',
        modalMessage: '',
        busy: false,
        canPurchase: true,
        showNoTicketsSelectedAlert: false,
        showMustPurchaseAdditionalTicketsAlert: false,
        showMustPurchaseAdditionalTicketsAlertMessage: '',
        showExpiredAlert: hasCartExpired(),
        showSeatFulfillmentFailedAlert: hasCartSeatFulfillmentFailed(),
        showAddToCalendar: false,
        showRemindMeModal: false,
        autoScanEnabled: preLoadeddata.autoScanPreference,
        chart: null,
        selectedSeats: {},
        itemCartView: [],
        showSeatSelectionCart: false,
        showTicketDialog: false,
        selectedFloor: "",
        holdToken: "",
        bestAvaliableSectionsSelected: {},
        seatsSelectionErrors: [],
        seatSelectionTicketTypeDialogData: {},
        seatSelectionConfirmSelectionCallback: null,
        allSeatIds: {}, // This is currently only being populated when we have a seated table event. This is filled when a section has 'table' in the name
        seatBundles: {}, // This will only be filled with items if a ticketType has the word 'whole' in the name.
    }),
    components: {
        Waitlist,
        Throttle,
        AccessCode,
        RegistrationForm,
        ModalRemindMe,
        ShareNetwork
    },
    mounted() {
        if(this.seatingSettings && this.seatingSettings.seatingChartKey ) {
            this.loadSeatsIOScript();
        }
      
        // Simulating an equivalent of document.ready (to be used in bootstrapping google tag manager to subscribe to events etc)
        this.$nextTick(function () {
            const applicationReadyEvent = new CustomEvent("application-ready", {
                detail: { when: Date() }
            })

            if (this.hideyShowyHackEnabled === true) {
                injectHideyShowyHack(this);
            }
          
            document.body.dispatchEvent(applicationReadyEvent);
            trackViewProduct({ id: this.eventId, name: this.eventName});            
        });
        setInterval(trackIdle, 60 * 1000);
    },
    watch: {
        // clear an alerts whenever there are changes
        // to requested inventory
        requestedInventory: {
            handler() {
                this.showNoTicketsSelectedAlert = false
                this.showMustPurchaseAdditionalTicketsAlert = false;
                (this.showMustPurchaseAdditionalTicketsAlertMessage = ''),
                    (this.showExpiredAlert = false)
                this.accessCode.showWarning = false

                const requestedInventoryChangedEvent = new CustomEvent(
                    "requested-inventory-changed",
                    { detail: { when: Date(), vm: this } }
                )
                document.body.dispatchEvent(requestedInventoryChangedEvent);
            },
            deep: true,
        },
        autoScanPreference: {
            handler() {
            },
            deep: true,
        }
    },
    computed: {
        numberOfTicketsSelected() {
            const itemsNotBundled = Object.values(this.selectedSeats).filter(x => !x.isBundle);
            const unBundleItemCount = Object.values(itemsNotBundled).reduce((total,item) => {
                return total + item.count
            }, 0);

            return unBundleItemCount + Object.keys(this.seatBundles).length;
        },
        showTotal() {
            return this.anyTicketsSelected
        },
        reserveSeatsSelected() {
            const totalValues = Object.values(this.selectedSeats);
            const itemsNotBundled = totalValues.filter(x => !x.isBundle);
            return [...itemsNotBundled, ...Object.values(this.seatBundles)]
        },
        selectedTickets() {
            return Object.keys(this.requestedInventory)
                .filter(inventoryId => this.requestedInventory[inventoryId] > 0)
                .map(inventoryId => {
                    const quantity = this.requestedInventory[inventoryId]
                    const inventoryItem = this.inventory[inventoryId]
                    
                    return {
                        inventoryId,
                        quantity,
                        name: inventoryItem.name,
                        price: inventoryItem.price,
                        total: inventoryItem.price * quantity,
                        bookingFee: inventoryItem.bookingFee,
                        totalBookingFee: inventoryItem.bookingFee * quantity,
                        requiresAnyOfTheseInventoriesToBeSelected:
                            inventoryItem.requiresAnyOfTheseInventoriesToBeSelected,
                        priceBase: inventoryItem.price - inventoryItem.bookingFee
                    }
                })
        },

        requestedInventoryToPost() {
            return this.selectedTickets.reduce((obj, ticket) => {
                obj[ticket.inventoryId] = ticket.quantity
                return obj
            }, {})
        },

        anyTicketsSelected() {
            return this.selectedTickets.length
        },

        total() {
            return this.selectedTickets
                .map(ticket => ticket.total)
                .reduce((total, current) => total + current, 0)
        },

        totalBookingFees() {
            return this.selectedTickets
                .map(ticket => ticket.totalBookingFee)
                .reduce((totalBookingFee, current) => totalBookingFee + current, 0)
        },

        showNextButton() {
            return this.pageIndex < this.totalNumberOfPages
        },

        showPreviousButton() {
            return this.totalNumberOfPages > 1 && this.showGetTicketsButton
        },

        showGetTicketsButton() {
            return !this.showNextButton
        },

        showTransactionFeeMessage() {
            return this.transactionFeesTotalDollarValue > 0 || this.transactionFeesTotalPercentValue > 0;
        },

        totalTransactionFeeAmount() {
            if (this.total < this.transactionFeesMinimumAmount) return 0;
            
            return (this.transactionFeesTotalDollarValue != null ? this.transactionFeesTotalDollarValue : 0)
                + (this.total * (this.transactionFeesTotalPercentValue != null ? (this.transactionFeesTotalPercentValue / 100) : 0));
        },
        showSeatSelection() {
            return this.showSeatSelectionCart;
        },
        showPicker() {
          return this.showTicketDialog;  
        },
        totalTransactionFeeMessage() {
            if (this.showTransactionFeeMessage) {
                if (this.anyTicketsSelected) {
                    return `additional Transaction Fee of $${this.totalTransactionFeeAmount.toFixed(2)} applies`;
                } else {
                    let transactionFeeAmountString = '';
                    if (this.transactionFeesTotalDollarValue > 0) {
                        transactionFeeAmountString += `$${this.transactionFeesTotalDollarValue.toFixed(2)}`;
                    }
                    if (this.transactionFeesTotalPercentValue > 0) {
                        if (transactionFeeAmountString.length > 0) {
                            transactionFeeAmountString += ' + ';
                        }
                        transactionFeeAmountString += `${this.transactionFeesTotalPercentValue.toFixed(2)}%`;
                    }
                    return `additional Transaction Fee of ${transactionFeeAmountString} applies`;
                }
            }
            return '';
        },
        disableUIInteraction() {
            if(this.seatingSettings != null) {
                return this.busy || !this.canPurchase;
            }
            
            return this.busy;
        }
    },
    methods: {
        loadSeatsIOScript() {
            const script = document.createElement("script");
            script.src = "https://cdn-oc.seatsio.net/chart.js";
            script.async = true;

            script.addEventListener('load', () => {
                this.loadSeatPicker();
            });
      
        document.body.appendChild(script);  
        window.addEventListener('message', (object) => {
            if(object.origin != "https://cdn-oc.seatsio.net")
                return;
            try {      
                var data = JSON.parse(object.data);      
                if(data.messageType =="updateSeats") {
                    if(data.label.toLowerCase().includes("table")) {
                        if(!this.allSeatIds[data.parent]) {
                            this.allSeatIds[data.parent] = [data.label]
                        }
                        else {
                            this.allSeatIds[data.parent].push(data.label)
                        }
                    }                    
                }
              } catch (e) {
                return false;
              }
        }, false);
        },
        showHideSeatSelect() {
            this.showSeatSelectionCart = !this.showSeatSelectionCart;
        },
        getValidators() {
            var validators = [];

            if(this.seatingSettings.enforceConsecutiveSeats) {
                validators.push({
                    type: 'consecutiveSeats'
                })
            };

            if(this.seatingSettings.enforceNoOrphanedSeats) {
                validators.push({
                    type: 'noOrphanSeats'
                })
            };

            return validators;
        },
        loadSeatPicker() { 
            const tickets = this.seatingSettings.seatedCategories.map(x => {
                return {
                    category: x.ticketPoolId, 
                    ticketTypes: x.ticketTypes
                }
            });
            
            const ticketTypes = tickets.map(x => {
               return x.ticketTypes; 
            });
            
            const ticketTypesFlattened = ticketTypes.concat.apply([], ticketTypes)
            
            const maximumSelectedItems = ticketTypesFlattened.map(ticket => {
                return {
                    ticketType: ticket.ticketType,
                    quantity: this.inventory[ticket.ticketType].maximumQuantity
                }
            });

            this.chart = new window.seatsio.SeatingChart({
                session: "start",
                divId: 'seatsPicker',        
                workspaceKey: this.seatingSettings.workspaceKey,
                event: this.eventId,        
                pricing: tickets,
                priceFormatter: function(price) {
                    return '$' + price;
                },
                maxSelectedObjects: maximumSelectedItems,
                onObjectSelected: this.reserveSeat,
                onObjectDeselected: this.unReserveSeat,
                onFloorChanged: (item) => {
                    this.selectedFloor = item ? item.name : "";
                },
                onTicketTypePrompt: this.showTicketTypesDialog,
                channels: ['NO_CHANNEL'],
                multiSelectEnabled: this.seatingSettings.useBestAvaliable ? false : this.seatingSettings.multiSelectEnabled,
                showFullScreenButton: this.seatingSettings.useBestAvaliable ? false : true,
                selectionValidators: this.getValidators(),
                onSelectionInvalid: (errors) => {
                    this.canPurchase = false;
                    this.seatsSelectionErrors = errors.map(x => {
                        return {
                            type: 'SeatsPickerError',
                            text: this.getSelectionErrorMessage(x)
                        }
                    });
                },
                onSelectionValid: () => {
                    this.canPurchase = true;
                    this.seatsSelectionErrors = [];
                },
                onHoldTokenExpired: () => {
                    this.canPurchase = false;   
                },
                onSessionInitialized: () => {
                    this.seatsSelectionErrors = [];
                    this.canPurchase = true;
                },
                showFullScreenButton: false,
                availableCategories: this.getAvaliableCategoriesOnMount(),
                showSectionContents: this.seatingSettings.showSectionContents,
                isObjectVisible: (object, extraConfig) => {
                    if(object.type = "seat") {
                        window.parent.postMessage(`{"label": "${object.label}","messageType":"updateSeats", "parent": "${object.labels.parent}"}`, extraConfig.parentUrl)
                    }

                    return true;

                },
                extraConfig: {
                    parentUrl: window.location.origin,
                }      
            }).render();

   
        },
        getAvaliableCategoriesOnMount() {
            return this.seatingSettings.useBestAvaliable ? ['none'] : this.seatingSettings.seatedCategories.map(x => x.ticketPoolId)
        },
        getOwnParentDisplayName(section, objectType) {
            if(objectType === "GeneralAdmissionArea")
                return "";

            if(section.toLowerCase().includes("table"))
                return "Table";

            return "Row";
        },
        showTicketTypesDialog (params, confirmSelection) {
            
            this.seatSelectionConfirmSelectionCallback = confirmSelection;

            const section = startCase(params.objectToSelect.labels.section);
            const ownType = startCase(params.objectToSelect.objectType);
            const parentType = this.getOwnParentDisplayName(section, params.objectToSelect.objectType);
            const parentNumber = params.objectToSelect.labels.parent;
            const ownNumber = params.objectToSelect.labels.own;

            if(this.isSeatObjectATable(section)) {
                const seatsWithParent = this.allSeatIds[parentNumber];
    
                const seatInfos = seatsWithParent.map(seatId => {
                    return this.chart.findObject(seatId);
                });
    
                Promise.all(seatInfos).then((objectInfos) => {
                    
                    var availableTicketTypes = params.ticketTypes.map(x => {
                        return {
                            Name: this.inventory[x.ticketType].name,
                            price: this.currencyFormat(this.inventory[x.ticketType].price),
                            ticketTypeId: x.ticketType,
                            bookingType: this.isTicketTypeABundle(x.ticketType) ? "bundle" : "single",
                            parentId: parentNumber,
                        }
                    });
    
                    const hasBookedSeats = objectInfos.filter(x => x.status !== "free").length > 0;
                    
                    if(hasBookedSeats) {
                        availableTicketTypes = availableTicketTypes.filter(x => x.bookingType != "bundle");
                    };
                        
                    this.seatSelectionTicketTypeDialogData = {
                        ticketTypes: availableTicketTypes,
                        sectionName: section,
                        row: `${parentType} ${parentNumber} ${ownType} ${ownNumber}`,
                        seatObject: params.objectToSelect,
                    }

                    this.showTicketDialog = true;
                    
                });
            }
            else {
                this.seatSelectionTicketTypeDialogData = {
                    ticketTypes: params.ticketTypes.map(x => {
                        return {
                            Name: this.inventory[x.ticketType].name,
                            price: this.currencyFormat(this.inventory[x.ticketType].price),
                            ticketTypeId: x.ticketType,
                            bookingType: this.isTicketTypeABundle(x.ticketType) ? "bundle" : "single",
                            parentId: parentNumber,
                        }
                    }),
                    sectionName: section,
                    row: `${parentType} ${parentNumber} ${ownType} ${ownNumber}`,
                    seatObject: params.objectToSelect,
                }

                this.showTicketDialog = true;
            }

            
        },
        selectSeatFromDialog(ticketTypeId, bookingType, parentNumber) {

            const selectionError =   [{
                type: 'SeatsPickerError',
                text: "Cannot reserve as there are seats already book"
            }];

            if(bookingType === "bundle") {
                const seatsWithParent = this.allSeatIds[parentNumber];
                this.chart.doSelectObjects(seatsWithParent.map(x => {
                    return {
                        id: x,
                        ticketType: ticketTypeId
                    }
                })).catch(() => {
                    this.seatsSelectionErrors = selectionError;
                });           
            }
            else {
                this.seatSelectionConfirmSelectionCallback(ticketTypeId)
                .catch(e => {
                    this.seatsSelectionErrors = selectionError;
                });
            }

            this.showTicketDialog = false;
                
        },
        cancelSelectSeatFromDialog() {
            this.showTicketDialog = false;
        },
        quantities(ticketId) {
            const size =
                this.inventory[ticketId].maximumQuantity <
                this.inventory[ticketId].minimumQuantity
                    ? this.inventory[ticketId].maximumQuantity
                    : this.inventory[ticketId].maximumQuantity -
                    this.inventory[ticketId].minimumQuantity +
                    1;

            return [...Array(size)].map((_, i) => ({
                key: `${ticketId}-${i}`,
                value: i + this.inventory[ticketId].minimumQuantity,
            }));
        },
        updateCategoriesOnSeatingChart(categories) {
            this.chart.changeConfig({
                availableCategories: categories // this will deselect objects that belong to an unavailable category
            });
        },
        ticketSelectonOnChangeEvent(event, ticketId) {
            const ticketCount = event.target.value;
            if(this.seatingSettings && this.seatingSettings.useBestAvaliable) {
                
                const categoryidfilter = this.seatingSettings.seatedCategories.filter(x => x.ticketTypes.filter(y => y.ticketType === ticketId).length > 0);
                const categoryKey = categoryidfilter[0].ticketPoolId;

                if(!this.bestAvaliableSectionsSelected[categoryKey] && ticketCount > 0) {
                    this.bestAvaliableSectionsSelected[categoryKey] = ticketCount;
                    this.updateCategoriesOnSeatingChart(Object.keys(this.bestAvaliableSectionsSelected));
                    return;
                };
                
                if(ticketCount == 0) {
                    delete this.bestAvaliableSectionsSelected[categoryKey];
                    if(Object.keys(this.bestAvaliableSectionsSelected).length === 0) {
                        this.updateCategoriesOnSeatingChart(['none']);
                    }
                    else {
                        this.updateCategoriesOnSeatingChart(Object.keys(this.bestAvaliableSectionsSelected));    
                    }
                };
                

            };
        },
        moveToNextPage() {
            this.pageIndex = this.pageIndex + 1
            const transitionInMs = 500
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, transitionInMs)
        },

        moveToPreviousPage() {
            this.pageIndex = this.pageIndex - 1
            this.showNoTicketsSelectedAlert = false
            this.showMustPurchaseAdditionalTicketsAlert = false
        },

        onSocialShareOpen(network, url) {
            trackShare(network, url)
        },

        showNoTicketsSelectedAlertAndSetFocus() {
            this.showNoTicketsSelectedAlert = true
            this.showExpiredAlert = false
            this.$nextTick(() => this.$refs.noTicketsSelectedAlert.focus())
        },

        showMustPurchaseAdditionalTicketsAlertAndFocus(message) {
            this.showMustPurchaseAdditionalTicketsAlert = true
            this.showMustPurchaseAdditionalTicketsAlertMessage = message
            this.showExpiredAlert = false
            this.$nextTick(() =>
                this.$refs.mustPurchaseAdditionalTicketsAlert.focus(),
            )
        },
        hasSeatAlreadyBeenAddedApartOfABundle(sectionId,parentId , ticketType) {
            return this.seatBundles[this.generateSeatBundleId(sectionId,parentId , ticketType)];
        },
        isTicketTypeABundle(ticketType) {
            return this.inventory[ticketType].name.toLowerCase().includes("table of");
        },
        generateSeatBundleId(sectionId,parentId , ticketType) {
            return `${sectionId}|${parentId}|${ticketType}`
        },
        isSeatObjectATable(sectionName) {
            return sectionName.toLowerCase().includes('table');
        },
        reserveSeat(seatInfo, selectedItem) {
            const ticketName = this.inventory[selectedItem.ticketType].name;
            const seatParent = seatInfo.labels.parent ?? "";
            const isBundle = this.isTicketTypeABundle(selectedItem.ticketType);
            const section = seatInfo.objectType === "GeneralAdmissionArea" ? seatInfo.label : seatInfo.labels.section ?? "";
            const seatNumber = seatInfo.objectType === "GeneralAdmissionArea" ? "0" : seatInfo.labels.own;
            if(isBundle) {      
                if(!this.hasSeatAlreadyBeenAddedApartOfABundle(section,seatParent,selectedItem.ticketType) ) {
                    this.seatBundles[this.generateSeatBundleId(section,seatParent,seatInfo.selectedTicketType)] = {
                        section: section,
                        rowNumber: seatParent,
                        price: this.inventory[selectedItem.ticketType].price,
                        parentType: this.getOwnParentDisplayName(section,seatInfo.objectType),
                        isBundle,
                        count: 1
                    }
                    this.addTicket(selectedItem.ticketType);
                }
            }
            else {
                this.addTicket(selectedItem.ticketType);
            }
     
            if(!this.selectedSeats[`${seatInfo.label}|${seatInfo.selectedTicketType}`]) {
                this.selectedSeats[seatInfo.label] = {
                    name: ticketName,
                    price: this.inventory[selectedItem.ticketType].price,
                    floor: this.selectedFloor ?? "",
                    section: section,
                    seatNumber: seatNumber,
                    rowNumber: seatParent,
                    type: seatInfo.objectType,
                    count: seatInfo.objectType === "GeneralAdmissionArea" ? seatInfo.numSelected: 1,
                    ticketPoolId: seatInfo.category.key,
                    ticketTypeId: seatInfo.selectedTicketType,
                    isBundle,
                    bundleId: this.generateSeatBundleId(section,seatParent,seatInfo.selectedTicketType),
                    parentType: this.getOwnParentDisplayName(section,seatInfo.objectType),
                };
            }
            else {
                this.selectedSeats[`${seatInfo.label}|${seatInfo.selectedTicketType}`].count++;
            };
        },
        unReserveSeat(seatInfo, selectedItem) {
            const seatParent = seatInfo.labels.parent ?? "";
            const section = seatInfo.objectType === "GeneralAdmissionArea" ? seatInfo.label : seatInfo.labels.section ?? "";
            
            if(this.isTicketTypeABundle(selectedItem.ticketType) && this.hasSeatAlreadyBeenAddedApartOfABundle(section,seatParent,selectedItem.ticketType)) {
                delete this.seatBundles[this.generateSeatBundleId(section,seatParent,selectedItem.ticketType)];
                this.removeTicket(selectedItem.ticketType);
                const adjacentSeats = this.allSeatIds[seatParent];
                this.chart.deselectObjects(adjacentSeats);

            }
            else {
                this.removeTicket(selectedItem.ticketType);
            }
            
            this.selectedSeats[seatInfo.label].count -= 1;
            if(this.selectedSeats[seatInfo.label].count <= 0 )
                delete this.selectedSeats[seatInfo.label];
        },
        addTicket(inventoryId) {
            const { minimumQuantity, maximumQuantity } = this.inventory[
                inventoryId
            ]
            const currentNumberOfTickets = this.requestedInventory[inventoryId]
            if (this.requestedInventory[inventoryId] < maximumQuantity) {
                const numberOfTicketsToAdd =
                    currentNumberOfTickets == 0 ? minimumQuantity : 1
                this.requestedInventory[inventoryId] += numberOfTicketsToAdd
            }
        },
        removeTicket(inventoryId) {
            const { minimumQuantity, maximumQuantity } = this.inventory[
                inventoryId
            ]
            const currentNumberOfTickets = this.requestedInventory[inventoryId]
            if (this.requestedInventory[inventoryId] != 0) {
                const numberOfTicketsToRemove =
                    currentNumberOfTickets == minimumQuantity
                        ? minimumQuantity
                        : 1
                this.requestedInventory[inventoryId] -= numberOfTicketsToRemove
            }
        },
        isRemoveDisabled(inventoryId) {
            return this.requestedInventory[inventoryId] == 0
        },

        isAddDisabled(inventoryId) {
            return (
                this.requestedInventory[inventoryId] ==
                this.inventory[inventoryId].maximumQuantity
            )
        },

        activeClass(inventoryId) {
            return this.requestedInventory[inventoryId] > 0 ? 'active' : ''
        },

        showOnCurrentPage(inventoryId) {
            return this.inventory[inventoryId].pageToShowOn == this.pageIndex
        },

        redirectToPage(redirectTo) {
            window.location = redirectTo
        },
        getSelectionErrorMessage(errorType) {
            switch (errorType){
                case 'noOrphanSeats':
                    return "No orphan seats are allowed"
                case 'consecutiveSeats':
                    return "You must select consecutive seats"
            }
        },
        handleReservationFailure() {
            this.showModalDialog(
                'Tickets are reserved',
                'The ticket(s) you requested are currently reserved by other customers. Try selecting a smaller number of tickets, a different ticket type or try again later.',
            )
        },

        handleError(error) {
            if (error instanceof AxiosRedirectIntercepted) return

            console.error('Something went wrong', error)

            this.showModalDialog(
                'Something went wrong',
                'Oops! Something went wrong. Wanna try again?',
            )
        },

        showModalDialog(title, message) {
            this.showModal = true
            this.modalTitle = title
            this.modalMessage = message
        },

        showAddToCalendarDialog() {
            this.showAddToCalendar = true
            document.body.className += ' ' + 'modal-open'
        },

        closeAddToCalendarDialog() {
            window.location.reload(true)
        },

        showBusy() {
            this.busy = true
            return Promise.resolve()
        },
        hideBusy() {
            this.busy = false
        },
        validate() {
            if (!this.anyTicketsSelected) {
                this.showNoTicketsSelectedAlertAndSetFocus()
                return false
            }

            var invalidTickets = this.ticketsRequiringOtherInventoriesToBeSelected()
            if (invalidTickets.length) {
                const message = `${invalidTickets.join(
                    ', ',
                )} can only be purchased with an additional ticket selection`
                this.showMustPurchaseAdditionalTicketsAlertAndFocus(message)
                return false
            }

            return true
        },

        ticketsRequiringOtherInventoriesToBeSelected() {
            var selectedInventoryIds = this.selectedTickets.map(
                ticket => ticket.inventoryId,
            )

            const ticketRequiresOtherInventoriesToBeSelected = ticket =>
                ticket.requiresAnyOfTheseInventoriesToBeSelected.length &&
                ticket.requiresAnyOfTheseInventoriesToBeSelected.every(
                    inventoryId =>
                        selectedInventoryIds.indexOf(inventoryId) == -1,
                )

            return this.selectedTickets
                .filter(ticketRequiresOtherInventoriesToBeSelected)
                .map(ticket => ticket.name)
        },

        trackCartActions(cartId) {
            var analyticsData = {
                eventId: this.eventId,
                eventName: this.eventName,
                inventory: this.selectedTickets.map(ticket => ({
                    id: ticket.inventoryId,
                    name: ticket.name,
                    quantity: ticket.quantity,
                    price: ticket.price,
                    priceBase: ticket.priceBase.toFixed(2)
                })),
                total: this.total,
                cartId: cartId,
                venueName: this.venueDescription
            }

            trackAddToCart(analyticsData);
            trackBeginCheckout(analyticsData);
        },
        
        submit(event) {
            if (!this.validate()) return
            
            this.showBusy()
                .then(this.postTickets(event.target.action))
                .then(response => {
                    const { status, redirectUrl, cartId } = response.data

                    if (status === RESERVATION_FAILED_STATUS) {
                        this.handleReservationFailure()
                        return
                    }

                    this.trackCartActions(cartId)
                    this.redirectToPage(redirectUrl)
                })
                .catch(this.handleError)
        },

        postTickets(url) {
            var reservedSeats = [];
            if(this.seatingSettings) {
                this.holdToken = this.chart.holdToken;

                if(this.seatingSettings.useBestAvaliable) {
                    reservedSeats = Object.keys(this.requestedInventoryToPost).map(inventoryId => {
                        const categoryidfilter = this.seatingSettings.seatedCategories.filter(x => x.ticketTypes.filter(y => y.ticketType === inventoryId).length > 0);
                        const categoryKey = categoryidfilter[0].ticketPoolId

                        return {
                            count: this.requestedInventoryToPost[inventoryId],
                            ticketPoolId: categoryKey,
                            ticketTypeId: inventoryId,
                        }
                    });
                }
                else {
                    reservedSeats = Object.keys(this.selectedSeats).map(key => {
                        return {
                            ...this.selectedSeats[key],
                            id: key,
                        }
                    });
                }
            }
           
            
            return () =>
                axios.post(url, {
                    accessCodeId: this.accessCode.accessCodeId,
                    accessCode: this.accessCode.accessCode,
                    autoScanForOrder: this.autoScanEnabled,
                    requestedInventory: this.requestedInventoryToPost,
                    reservedSeats: reservedSeats,
                    holdToken: this.chart ? this.chart.holdToken : "",
                })
        },

        addCurrentQuerystringIfAnchorClicked(event) {
            const wasAnAnchorClick =
                event && event.target && event.target.tagName
                    ? event.target.tagName.toLowerCase() === 'a'
                    : false

            if (!wasAnAnchorClick) return false

            const originalHref = event.target.href

            // need to use .event.target.getAttribute('href') rather than event.target.href
            // as event.target.href will always return an absolute url
            var isHrefIsAFragment = (event.target.getAttribute('href') || '').indexOf('#') === 0
            if (isHrefIsAFragment) {
                window.location.href = originalHref
                return
            }

            const currentQuerystring = window.location.search.substring(1)

            if (currentQuerystring === '') {
                window.location.href = originalHref
            } else {
                const originalHrefHasQuerystring =
                    originalHref.indexOf('?') !== -1
                const separator = originalHrefHasQuerystring ? '&' : '?'

                window.location.href =
                    originalHref + separator + currentQuerystring
            }
        },

        handleRemindMeClick() {
            this.showRemindMeModal = true
        },

        closeRemindMeModal() {
            this.showRemindMeModal = false
        },

        getButtonPriceDescription(deliveryFeeMessage) {
            let bookingFeeText = '';
            
            if (this.anyTicketsSelected) {
                bookingFeeText = `Price includes Booking Fee of $${this.totalBookingFees.toFixed(2)}.`;
            } else {
                bookingFeeText = `Price includes Booking Fees.`;
            }
            
            let additionalFeeArray = [this.totalTransactionFeeMessage, deliveryFeeMessage].filter(Boolean);
            
            if (additionalFeeArray.length > 0) {
                bookingFeeText += ' An ' + additionalFeeArray.join(' and an ') + ' per order.';
            }
            
            return bookingFeeText;
        },

        updateAutoScanCallback(newValue) {
            this.autoScanPreference = newValue;
        },

        currencyFormat
    },
});

vm.mount("#events-page")
